import { SET_AVAIL_CHECKS } from "../types";

const initialState = {
  availableNow: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AVAIL_CHECKS:
      return {
        ...state,
        availableNow: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
