import { CHANGE_COMPONENT, TOGGLE_SIDENAV } from "../types";

const initialState = {
  isLoading: false,
  error: null,
  currentComponent: "About",
  showSideNav: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_COMPONENT:
      return {
        ...state,
        currentComponent: action.payload,
      };
    case TOGGLE_SIDENAV:
      return {
        ...state,
        showSideNav: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
