import React, { Suspense, useEffect } from 'react';
import './App.css';
import NavBar from './components/NavBar';
import ParticleBG from './components/ParticleBG';
import { Route, Redirect, useLocation } from 'react-router-dom';
import Switch from 'react-bootstrap/esm/Switch';
import axios from 'axios';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-GGG3227VLH');

// import Projects from "./components/Projects";
const Component = React.lazy(() => import('./components/Component'));
const Projects = React.lazy(() => import('./components/Projects'));
const About = React.lazy(() => import('./components/About'));
const Experience = React.lazy(() => import('./components/Experience'));
const Education = React.lazy(() => import('./components/Education'));
const Skills = React.lazy(() => import('./components/Skills'));
const Timeline = React.lazy(()=>import("./components/Timeline"));

function App() {
  function loadPage() {
    axios
      .get(`https://ipinfo.io`, {
        headers: { Authorization: `Bearer 202eebbf60a881` },
      })
      .then((res) => {
        if (res.data) {
          axios.post(`${process.env.REACT_APP_API_URL}/loadpage`, res.data);
        }
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    // loadPage();
    return () => {};
  }, []);

  const location = useLocation();
  useEffect(() => {
    let pagePath = location.pathname + location.search;
    const sendPageView = () => {
      ReactGA.send({
        hitType: 'pageview',
        page: pagePath,
        title: document.title,
      });
    };
    sendPageView();
  }, [location]);
  return (
    <>
      <link
        href="https://fonts.googleapis.com/css?family=Saira+Extra+Condensed:500,700"
        rel="stylesheet"
        type="text/css"
      />
      <link
        href="https://fonts.googleapis.com/css?family=Muli:400,400i,800,800i"
        rel="stylesheet"
        type="text/css"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Play:wght@700&display=swap"
        rel="stylesheet"
      ></link>
      <NavBar />
      <div className="wrapper" style={{ zIndex: '-10' }}>
        <div id="particles-js">
          <ParticleBG />
        </div>

        <div className="content">
          <Suspense fallback={<div></div>}>
            <Switch>
              <Route path="/" exact component={Component} />
              <Route path="/about" exact component={About} />
              <Route path="/experience" exact component={Experience} />
              <Route path="/education" exact component={Education} />
              <Route path="/skills" exact component={Skills} />
              <Route path="/projects" exact component={Projects} />
              <Route path="/timeline" exact component={Timeline} />
            </Switch>
          </Suspense>
        </div>
      </div>
      <style>{`
      .wrapper{
         height:100%;
      }
      .content{
          position: relative;
          z-index: 4;
          }
          .custom-switch{
            padding-left:0
          }
  `}</style>

      {/* <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a>
        </header>
      </div> */}
    </>
  );
}

export default App;
