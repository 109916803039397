import { CHANGE_COMPONENT } from "../types";
import { TOGGLE_SIDENAV } from "../types";
// Actions
export const changeComponent = (val) => {
  return { type: CHANGE_COMPONENT, payload: val };
};
export const showSideNav = (val) => {
  return { type: TOGGLE_SIDENAV, payload: val };
};
// export const networkError = (err) => {
//   return { type: NETWORK_ERROR, error: err };
// };
// export const addAP = (ap) => {
//   return { type: ADD_AP, payload: ap };
// };

// export const changeFormValues = (formValues) => {
//   return { type: CHANGE_APFORM, payload: formValues };
// };
// export const changeSSIDForm = (ssidForm) => {
//   return { type: CHANGE_SSIDFORM, payload: ssidForm };
// };
// export const changeAPGroupForm = (groupForm) => {
//   return { type: CHANGE_APGROUPFORM, payload: groupForm };
// };
// export const toggleMap = (val) => {
//   return { type: TOGGLE_MAP, payload: val };
// };
// export const setCoordinates = (payload) => {
//   return { type: SET_COORDINATES, lng: payload.lng, lat: payload.lat };
// };
// export const addSSID = (ssid) => {
//   return { type: ADD_SSID, payload: ssid };
// };
// export const addAPGroup = (apgroup) => {
//   return { type: ADD_APGROUP, payload: apgroup };
// };
// export const setSSIDs = (ssids) => {
//   return { type: GET_SSIDS, payload: ssids };
// };
// export const setAPs = (aps) => {
//   return { type: GET_APS, payload: aps };
// };
// export const setModels = (models) => {
//   return { type: GET_MODELS, payload: models };
// };
// export const setSecret = (secret) => {
//   return { type: GET_SECRET, payload: secret };
// };
// export const setAPGroups = (apgroups) => {
//   return { type: GET_APGROUPS, payload: apgroups };
// };
// export const setAPsComponent = (current) => {
//   return { type: SET_APSCOMPONENT, payload: current };
// };
// export const setSSIDsComponent = (current) => {
//   return { type: SET_SSIDSCOMPONENT, payload: current };
// };
// export const setGroupsComponent = (current) => {
//   return { type: SET_GROUPSCOMPONENT, payload: current };
// };

// export const getModels = () => {
//   return (dispatch) => {
//     axios({
//       method: "get",
//       headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
//       url: `${process.env.REACT_APP_API_URL}/network/models`,
//     })
//       .then((result) => {
//         if (result.data.models) dispatch(setModels(result.data.models));
//       })
//       .catch((err) => {
//         dispatch(networkError(err));
//       });
//   };
// };

// export const getSSIDs = () => {
//   return (dispatch) => {
//     axios({
//       method: "get",
//       headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
//       url: `${process.env.REACT_APP_API_URL}/network/ssids/all`,
//     })
//       .then((result) => {
//         console.log(result.data);
//         dispatch(setSSIDs(result.data));
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };
// };

// export const getSecret = () => {
//   return (dispatch) => {
//     axios({
//       method: "get",
//       headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
//       url: `${process.env.REACT_APP_API_URL}/network/secret`,
//     })
//       .then((result) => {
//         dispatch(setSecret(result.data.secret));
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };
// };

// export const getAPGroups = () => {
//   return (dispatch) => {
//     axios({
//       method: "get",
//       headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
//       url: `${process.env.REACT_APP_API_URL}/network/groups/all`,
//     })
//       .then((result) => {
//         console.log(result.data);
//         dispatch(setAPGroups(result.data));
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };
// };

// export const getAPs = () => {
//   return (dispatch) => {
//     axios({
//       method: "get",
//       headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
//       url: `${process.env.REACT_APP_API_URL}/network/accesscontrollers/all`,
//     })
//       .then((result) => {
//         console.log(result.data);
//         dispatch(setAPs(result.data));
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };
// };

// export const addNewAP = (ap) => {
//   return (dispatch) => {
//     dispatch(setNetworkLoading(true));
//     axios({
//       method: "post",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${localStorage.getItem("token")}`,
//       },
//       url: `${process.env.REACT_APP_API_URL}/network/accesscontrollers/add`,
//       data: ap,
//     })
//       .then((result) => {
//         dispatch(setNetworkLoading(false));
//         if (result.data.message === "Added") {
//           dispatch(addAP(result.data.data));
//           toast.success("✔ Added Successfully");
//           dispatch(setAPsComponent("overview"));
//           dispatch(changeFormValues({ model: "choose", protocol: "HTTP" }));
//         } else {
//           dispatch(networkError(result.data.message));
//         }
//       })
//       .catch((err) => console.log(err));
//   };
// };

// export const updateAP = (ap) => {
//   return (dispatch, getState) => {
//     dispatch(setNetworkLoading(true));
//     axios({
//       method: "put",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${localStorage.getItem("token")}`,
//       },
//       url: `${process.env.REACT_APP_API_URL}/network/accesscontrollers/edit`,
//       data: ap,
//     })
//       .then((result) => {
//         dispatch(setNetworkLoading(false));
//         if (result.data.message === "Updated") {
//           let data = getState().network.aps;
//           let index = data.findIndex((ac) => ac.id === ap.id);
//           data.splice(index, 1);
//           data.splice(index, 0, ap);
//           dispatch(setAPs(data));
//           toast.success("✔ Updated Successfully");
//           dispatch(setAPsComponent("overview"));
//           dispatch(changeFormValues({ model: "choose", protocol: "HTTP" }));
//         } else {
//           dispatch(networkError(result.data.message));
//         }
//       })
//       .catch((err) => console.log(err));
//   };
// };

// export const addNewSSID = (ssid) => {
//   return (dispatch) => {
//     dispatch(setNetworkLoading(true));
//     axios({
//       method: "post",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${localStorage.getItem("token")}`,
//       },
//       url: `${process.env.REACT_APP_API_URL}/network/ssids/add`,
//       data: ssid,
//     })
//       .then((result) => {
//         dispatch(setNetworkLoading(false));
//         if (result.data.message === "Added") {
//           dispatch(addSSID(result.data.data));
//           toast.success("✔ Added Successfully");
//           dispatch(setSSIDsComponent("overview"));
//           dispatch(changeSSIDForm({}));
//         } else {
//           dispatch(networkError(result.data.message));
//         }
//       })
//       .catch((err) => console.log(err));
//   };
// };

// export const updateSSID = (ssid) => {
//   return (dispatch, getState) => {
//     dispatch(setNetworkLoading(true));
//     axios({
//       method: "put",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${localStorage.getItem("token")}`,
//       },
//       url: `${process.env.REACT_APP_API_URL}/network/ssids/edit`,
//       data: ssid,
//     })
//       .then((result) => {
//         dispatch(setNetworkLoading(false));
//         if (result.data.message === "Updated") {
//           let data = getState().network.ssids;
//           let index = data.findIndex((ss) => ss.ID === ssid.ID);
//           data.splice(index, 1);
//           data.splice(index, 0, ssid);
//           dispatch(setSSIDs(data));
//           toast.success("✔ Updated Successfully");
//           dispatch(setSSIDsComponent("overview"));
//           dispatch(changeSSIDForm({}));
//         } else {
//           dispatch(networkError(result.data.message));
//         }
//       })
//       .catch((err) => console.log(err));
//   };
// };
// export const addNewGroup = (apgroup) => {
//   return (dispatch) => {
//     dispatch(setNetworkLoading(true));
//     axios({
//       method: "post",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${localStorage.getItem("token")}`,
//       },
//       url: `${process.env.REACT_APP_API_URL}/network/groups/add`,
//       data: apgroup,
//     })
//       .then((result) => {
//         dispatch(setNetworkLoading(false));
//         if (result.data.message === "Added") {
//           dispatch(addAPGroup(result.data.data));
//           toast.success("✔ Added Successfully");
//           dispatch(setGroupsComponent("overview"));
//           dispatch(changeAPGroupForm({ APs: "" }));
//         } else {
//           dispatch(networkError(result.data.message));
//         }
//       })
//       .catch((err) => console.log(err));
//   };
// };
// export const updateGroup = (apgroup) => {
//   return (dispatch, getState) => {
//     dispatch(setNetworkLoading(true));
//     axios({
//       method: "put",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${localStorage.getItem("token")}`,
//       },
//       url: `${process.env.REACT_APP_API_URL}/network/groups/edit`,
//       data: apgroup,
//     })
//       .then((result) => {
//         dispatch(setNetworkLoading(false));
//         if (result.data.message === "Updated") {
//           let data = getState().network.apgroups;
//           let index = data.findIndex((ss) => ss.ID === apgroup.ID);
//           data.splice(index, 1);
//           apgroup.APCount = apgroup.APs.split(",").length;
//           data.splice(index, 0, apgroup);
//           dispatch(setAPGroups(data));
//           toast.success("✔ Updated Successfully");
//           dispatch(setGroupsComponent("overview"));
//           dispatch(changeAPGroupForm({ APs: "" }));
//         } else {
//           dispatch(networkError(result.data.message));
//         }
//       })
//       .catch((err) => console.log(err));
//   };
// };
