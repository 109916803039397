// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from 'react';
import { connect } from 'react-redux';
import { bubble as Menu } from 'react-burger-menu';
import * as actions from '../store/actions/index';
import { NavLink, useLocation } from 'react-router-dom';

import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const menuItems = [
  'About',
  'Experience',
  'Education',
  'Skills',
  'Projects',
  'Timeline',
];
const NavBar = ({
  // changeComponent,
  // currentComponent,
  // changeShowSideNav,
  showSideNav,
}) => {
  const location = useLocation();
  const [menuOpen, setMenuOpen] = React.useState(false);
  React.useEffect(() => {
    setMenuOpen(false);
  }, [location]);
  const handleMenuClick = () => setMenuOpen(true);

  return (
    <>
      <div
        id="burgerContainer"
        style={{
          zIndex: '-1',
          display: `${window.screen.width > 700 ? 'none' : ''}`,
          outline: 'none',
          fontFamily: 'Play,sans serif',
        }}
      >
        <Menu
          customBurgerIcon={<img src="whitemenu.png" />}
          outerContainerId={'burgerContainer'}
          pageWrapId={'wrapper'}
          isOpen={menuOpen}
        >
          <span
            class="d-none d-lg-block"
            style={{ outline: 'none', textAlign: 'center' }}
          >
            <img
              className="img-fluid img-profile rounded-circle mx-auto mb-2"
              src="av1.png"
              alt=""
              style={{ width: '50%', height: 'auto' }}
            />
          </span>
          {menuItems.map((item, index) => {
            return (
              <NavLink
                id={`${item.toLowerCase()}`}
                key={index}
                className="menu-item"
                to={`/${item.toLowerCase()}`}
                style={{ textDecoration: 'none' }}
                activeClassName="active"
                onClick={handleMenuClick}
              >
                {item}
              </NavLink>
            );
          })}
          <a
            className="menu-item"
            href="/AdamsResume.pdf"
            download="AdamsResume.pdf"
            style={{ textDecoration: 'none' }}
          >
            <FontAwesomeIcon icon={faDownload} />
            <span className="ml-2">Resume</span>
          </a>
        </Menu>
      </div>
      <nav
        className="navbar navbar-expand-lg navbar-dark bg-primary fixed-top"
        id="sideNav"
        style={{
          zIndex: '100',
          background: '#A13A06',
          fontFamily: 'Play,sans serif',
        }}
      >
        <a className="navbar-brand js-scroll-trigger">
          <span className="">&nbsp;</span>
          <span className="d-none d-lg-block">
            <img
              className="img-fluid img-profile rounded-circle mx-auto mb-2"
              src="av1.png"
              alt=""
            />
          </span>
        </a>

        {/* <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          onClick={(e) => changeShowSideNav(!showSideNav)}
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button> */}
        <div
          className={`collapse navbar-collapse ${showSideNav ? 'show' : ''}`}
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav">
            {menuItems.map((item, index) => {
              return (
                <li key={index} className="nav-item">
                  <NavLink
                    className={`nav-link js-scroll-trigger`}
                    activeClassName="active"
                    to={`/${item.toLowerCase()}`}
                    // onClick={(e) => changeComponent("About")}
                  >
                    {item}
                  </NavLink>
                </li>
              );
            })}
            <li key="download_cv" className="nav-item">
              <a
                className={`nav-link js-scroll-trigger`}
                href="/AdamsResume.pdf"
                download="AdamsResume.pdf"
                style={{ textDecoration: 'none' }}
              >
                <FontAwesomeIcon icon={faDownload} />
                <span className="ml-2">Resume</span>
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <style>
        {`a{
          text-decoration:none;
        }
        .nav-item>a{
           transition: 1s       
        }
        .nav-item>a:hover{
          cursor:pointer;
          background-color: #171615
        }
#page-wrap {
  text-align: center;

  /* Prevent sidebar from showing a scrollbar on page */
  overflow: auto;
}

/* Individual item */
.menu-item{
  outline:none;
  text-transform: uppercase;
  font-weight:bold;
  text-align:center;
  margin-top: 20px;
  text-decoration:none; !important
}

.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

/* Change color on hover */
.bm-item:hover {
  color: white;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 30px;
  height: 30px;
  left: 25px;
  top: 15px;
  color: silver;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #A13A06;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #A13A06;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
        `}
      </style>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    currentComponent: state.home.currentComponent,
    showSideNav: state.home.showSideNav,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeComponent: (e) => dispatch(actions.changeComponent(e)),
    changeShowSideNav: (e) => dispatch(actions.showSideNav(e)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
